<template>
    <section class="publish-management">
        <div class="top-bar bg-white">
            <el-button type="primary" icon="el-icon-plus" class="addPublish" size="small"
                       @click="handleAddEditPublish('add',{})">
                新增房型
            </el-button>
            <el-input v-model="formSearch.keyword" placeholder="请输入关键字搜索"
                      style="margin-right: 9px; width: 250px" size="small"/>
            <el-button type="primary" @click="handleSearch" size="small">查询</el-button>
            <el-button @click="formSearch={keyword:''};handleSearch()" size="small">重置</el-button>
        </div>

        <r-e-table class="bg-white" ref="publishTableRef" :dataRequest="getPublishTableData" :columns="tableColumn"
                   :afterDataReq="afterDataReq" :query="formSearch" :height="820">
            <el-table-column slot="toolbar" label="操作" width="200">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <span class="table-btn" @click="handleAddEditPublish('edit', row)">查看</span>
                        <span class="table-btn" @click="handleRelationPublish(row)">关联房源</span>
                        <span class="table-btn" @click="handlePublishDelete(row)">删除</span>
                    </div>
                </template>
            </el-table-column>
            <template slot="empty">
                <el-empty/>
            </template>
        </r-e-table>
        <!--    添加、修改房源弹框   -->
        <layer-add-edit-publish class="layer-add-edit-publish" ref="publishRef"/>
        <!--    关联房源弹框   -->
        <layer-relation-publish class="layer-relation-publish" ref="relationRef"/>
    </section>
</template>

<script>
import * as api from "@/api/publish";
import {tableColumn} from "@/views/rental-management/publish-create/data";
import {layerAddEditPublish, layerRelationPublish} from "@/views/rental-management/publish-create/components";
import {MessageSuccess} from "@custom/message";

export default {
    name: "publish-create",
    data() {
        return {
            tableColumn,
            formSearch: {},
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
        };
    },
    components: {layerAddEditPublish, layerRelationPublish},
    mounted() {},
    methods: {
        //房型列表请求
        getPublishTableData(params) {
            return api.getPublishListApi(params);
        },
        //房型列表请求完成回调
        afterDataReq(e) {
            e.forEach(v => {
                /*if(v.houseType) {
                  v.houseType=v.houseType.replace(/[\u4e00-\u9fa5]/g,"");
                  let n=v.houseType;
                  v.houseType=[n[0]?n[0]:"0",n[1]?n[1]:"0",n[2]?n[2]:"0",n[3]?n[3]:"0"];
                }*/
                v.areaM = isNaN(v.area) ? v.area : v.area + "㎡";
            });
        },
        //点击查询按钮事件
        handleSearch() {
            //调用组件方法查询房型列表
            this.$refs.publishTableRef.getTableData();
        },

        //新增或修改房型
        handleAddEditPublish(addEdit, row) {
            // 新增、编辑打开弹窗
            this.$refs.publishRef.layerAddEditPublishInit(addEdit, row);
        },

        // 关联房源
        handleRelationPublish(row) {
            // 关联房源打开弹窗
            this.$refs.relationRef.layerRelationPublishInit(row);
        },

        //点击删除房型事件
        handlePublishDelete(row) {
            const that = this;
            //解构出当前房型uuid
            const {uuid} = row;
            //删除房型提醒
            this.$confirm('此操作将删除该房型, 是否继续?', '注意', {type: 'warning'}).then(() => {
                const loadingOptions = that.loadingOptions;
                const loading = that.$loading({...loadingOptions});
                //调用接口删除房型
                api.publishDeleteApi({uuid}).then(res => {
                    MessageSuccess('房型删除成功');
                    loading.close();
                    //调用查询，刷新数据
                    that.handleSearch();
                }).catch(err => loading.close());
            }).catch(() => {});
        }
    }
};
</script>

<style lang="scss" scoped>
.publish-management {
    padding: 0 VW(15px);

    .top-bar {
        display: flex;
        margin: VH(10px) 0 VH(15px);
        padding: VH(15px) VW(15px);
        justify-content: center;

        .addPublish {
            position: absolute;
            left: VW(40px);
        }
    }

    /deep/ .r-e-table {
        .el-table .cell {
            white-space: nowrap;
            text-align: center;
        }
    }
}
</style>
